<template>
  <nav>
    <ul :class="$style.links">
      <li><a href="https://rootle.de/page/datenschutz" target="_blank">Datenschutzerklärung</a></li>
      <li><a href="https://rootle.de/page/agb/" target="_blank">Nutzungsbedingungen</a></li>
    </ul>
  </nav>
</template>
<style lang="scss" module>
.links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 10px 0;

  li + li::before {
    content: "•";
    margin: 0 8px;
  }
}
</style>